<template>
    <div class="row">
        <div class="col-12 col-md-9">
            <edit-article
                ref="editArticle"
                :startup-id="startupId"
                v-on:saved="saved"
                v-on:canSave="setCanSave">
            </edit-article>
        </div>
        <div class="col-12 col-md-3">
            <b-button class="w-100 w-md-auto" variant="primary" size="lg" @click="save" :disabled="!canSave">Add</b-button>
        </div>
    </div>
</template>

<script>
import EditArticle from "@/components/articles/EditArticle";

export default {
    components: {
        EditArticle,
    },

    props: {
        startupId: Number,
    },

    data() {
        return {
            canSave: false,
        }
    },

    methods: {
        init() {
            this.$refs.editArticle.init(0)
        },

        save() {
            this.$refs.editArticle.save()
        },

        saved() {
            this.$emit('saved')
            this.init()
        },

        setCanSave(canSave) {
            this.canSave = canSave
        }
    }
}
</script>

<style scoped>

</style>